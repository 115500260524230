import type { AxiosRequestHeaders } from 'axios';
import type { AppStore } from 'app/store';
import { AppStorage } from '../../appStorage/AppStorage';

/**
 * Function that adds headers to the axios config. Is mutating the headers object so has no return variable
 */
export const setHeaders = async ({ headers }: { headers: AxiosRequestHeaders; store: AppStore }) => {
  const deviceId = await AppStorage.getItem('deviceId');

  if (deviceId) {
    headers.deviceId = deviceId;
  }
};
