/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  Login200,
  LoginBody,
  Refresh200,
  RefreshBody,
  RegisterDeviceBody,
  RevokeSession200,
  RevokeSessionBody,
  RevokeSessionDeprecated200,
  RevokeSessionDeprecatedBody,
  WebLoginCallbackParams,
  WebLoginParams,
} from '../api.schemas';
import { customAxios } from '../../axios';

export const registerDevice = (registerDeviceBody: RegisterDeviceBody, signal?: AbortSignal) => {
  return customAxios<void>({
    url: `/v2/session/device/register`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: registerDeviceBody,
    signal,
  });
};

export const getRegisterDeviceMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registerDevice>>,
    TError,
    { data: RegisterDeviceBody },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof registerDevice>>, TError, { data: RegisterDeviceBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof registerDevice>>, { data: RegisterDeviceBody }> = (
    props
  ) => {
    const { data } = props ?? {};

    return registerDevice(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RegisterDeviceMutationResult = NonNullable<Awaited<ReturnType<typeof registerDevice>>>;
export type RegisterDeviceMutationBody = RegisterDeviceBody;
export type RegisterDeviceMutationError = unknown;

export const useRegisterDevice = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registerDevice>>,
    TError,
    { data: RegisterDeviceBody },
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof registerDevice>>, TError, { data: RegisterDeviceBody }, TContext> => {
  const mutationOptions = getRegisterDeviceMutationOptions(options);

  return useMutation(mutationOptions);
};
export const login = (loginBody: LoginBody, signal?: AbortSignal) => {
  return customAxios<Login200>({
    url: `/v2/session/login`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: loginBody,
    signal,
  });
};

export const getLoginMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof login>>, TError, { data: LoginBody }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof login>>, TError, { data: LoginBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof login>>, { data: LoginBody }> = (props) => {
    const { data } = props ?? {};

    return login(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type LoginMutationResult = NonNullable<Awaited<ReturnType<typeof login>>>;
export type LoginMutationBody = LoginBody;
export type LoginMutationError = unknown;

export const useLogin = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof login>>, TError, { data: LoginBody }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof login>>, TError, { data: LoginBody }, TContext> => {
  const mutationOptions = getLoginMutationOptions(options);

  return useMutation(mutationOptions);
};
export const refresh = (refreshBody: RefreshBody, signal?: AbortSignal) => {
  return customAxios<Refresh200>({
    url: `/v2/session/refresh`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: refreshBody,
    signal,
  });
};

export const getRefreshMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof refresh>>, TError, { data: RefreshBody }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof refresh>>, TError, { data: RefreshBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof refresh>>, { data: RefreshBody }> = (props) => {
    const { data } = props ?? {};

    return refresh(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RefreshMutationResult = NonNullable<Awaited<ReturnType<typeof refresh>>>;
export type RefreshMutationBody = RefreshBody;
export type RefreshMutationError = unknown;

export const useRefresh = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof refresh>>, TError, { data: RefreshBody }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof refresh>>, TError, { data: RefreshBody }, TContext> => {
  const mutationOptions = getRefreshMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @deprecated
 */
export const revokeSessionDeprecated = (revokeSessionDeprecatedBody: RevokeSessionDeprecatedBody) => {
  return customAxios<RevokeSessionDeprecated200>({
    url: `/v2/session/revokeSession`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: revokeSessionDeprecatedBody,
  });
};

export const getRevokeSessionDeprecatedMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof revokeSessionDeprecated>>,
    TError,
    { data: RevokeSessionDeprecatedBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof revokeSessionDeprecated>>,
  TError,
  { data: RevokeSessionDeprecatedBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof revokeSessionDeprecated>>,
    { data: RevokeSessionDeprecatedBody }
  > = (props) => {
    const { data } = props ?? {};

    return revokeSessionDeprecated(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RevokeSessionDeprecatedMutationResult = NonNullable<Awaited<ReturnType<typeof revokeSessionDeprecated>>>;
export type RevokeSessionDeprecatedMutationBody = RevokeSessionDeprecatedBody;
export type RevokeSessionDeprecatedMutationError = unknown;

/**
 * @deprecated
 */
export const useRevokeSessionDeprecated = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof revokeSessionDeprecated>>,
    TError,
    { data: RevokeSessionDeprecatedBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof revokeSessionDeprecated>>,
  TError,
  { data: RevokeSessionDeprecatedBody },
  TContext
> => {
  const mutationOptions = getRevokeSessionDeprecatedMutationOptions(options);

  return useMutation(mutationOptions);
};
export const revokeSession = (revokeSessionBody: RevokeSessionBody) => {
  return customAxios<RevokeSession200>({
    url: `/v2/session/revoke-session`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: revokeSessionBody,
  });
};

export const getRevokeSessionMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof revokeSession>>,
    TError,
    { data: RevokeSessionBody },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof revokeSession>>, TError, { data: RevokeSessionBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof revokeSession>>, { data: RevokeSessionBody }> = (
    props
  ) => {
    const { data } = props ?? {};

    return revokeSession(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RevokeSessionMutationResult = NonNullable<Awaited<ReturnType<typeof revokeSession>>>;
export type RevokeSessionMutationBody = RevokeSessionBody;
export type RevokeSessionMutationError = unknown;

export const useRevokeSession = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof revokeSession>>,
    TError,
    { data: RevokeSessionBody },
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof revokeSession>>, TError, { data: RevokeSessionBody }, TContext> => {
  const mutationOptions = getRevokeSessionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const webLogin = (params: WebLoginParams, signal?: AbortSignal) => {
  return customAxios<void>({ url: `/v2/session/web-login`, method: 'GET', params, signal });
};

export const getWebLoginQueryKey = (params: WebLoginParams) => {
  return [`/v2/session/web-login`, ...(params ? [params] : [])] as const;
};

export const getWebLoginQueryOptions = <TData = Awaited<ReturnType<typeof webLogin>>, TError = unknown>(
  params: WebLoginParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof webLogin>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getWebLoginQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof webLogin>>> = ({ signal }) => webLogin(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof webLogin>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type WebLoginQueryResult = NonNullable<Awaited<ReturnType<typeof webLogin>>>;
export type WebLoginQueryError = unknown;

export function useWebLogin<TData = Awaited<ReturnType<typeof webLogin>>, TError = unknown>(
  params: WebLoginParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof webLogin>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof webLogin>>, TError, TData>, 'initialData'>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useWebLogin<TData = Awaited<ReturnType<typeof webLogin>>, TError = unknown>(
  params: WebLoginParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof webLogin>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof webLogin>>, TError, TData>, 'initialData'>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useWebLogin<TData = Awaited<ReturnType<typeof webLogin>>, TError = unknown>(
  params: WebLoginParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof webLogin>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useWebLogin<TData = Awaited<ReturnType<typeof webLogin>>, TError = unknown>(
  params: WebLoginParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof webLogin>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getWebLoginQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const webLoginCallback = (params: WebLoginCallbackParams, signal?: AbortSignal) => {
  return customAxios<void>({ url: `/v2/session/web-login/callback`, method: 'GET', params, signal });
};

export const getWebLoginCallbackQueryKey = (params: WebLoginCallbackParams) => {
  return [`/v2/session/web-login/callback`, ...(params ? [params] : [])] as const;
};

export const getWebLoginCallbackQueryOptions = <TData = Awaited<ReturnType<typeof webLoginCallback>>, TError = unknown>(
  params: WebLoginCallbackParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof webLoginCallback>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getWebLoginCallbackQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof webLoginCallback>>> = ({ signal }) =>
    webLoginCallback(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof webLoginCallback>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type WebLoginCallbackQueryResult = NonNullable<Awaited<ReturnType<typeof webLoginCallback>>>;
export type WebLoginCallbackQueryError = unknown;

export function useWebLoginCallback<TData = Awaited<ReturnType<typeof webLoginCallback>>, TError = unknown>(
  params: WebLoginCallbackParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof webLoginCallback>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof webLoginCallback>>, TError, TData>, 'initialData'>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useWebLoginCallback<TData = Awaited<ReturnType<typeof webLoginCallback>>, TError = unknown>(
  params: WebLoginCallbackParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof webLoginCallback>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof webLoginCallback>>, TError, TData>, 'initialData'>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useWebLoginCallback<TData = Awaited<ReturnType<typeof webLoginCallback>>, TError = unknown>(
  params: WebLoginCallbackParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof webLoginCallback>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useWebLoginCallback<TData = Awaited<ReturnType<typeof webLoginCallback>>, TError = unknown>(
  params: WebLoginCallbackParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof webLoginCallback>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getWebLoginCallbackQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}
