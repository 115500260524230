'use client';
import { axiosInstance } from '@cxnpl/api/axios';
import { useEffect, useMemo, type ReactNode } from 'react';
import { Provider, type ProviderProps } from 'react-redux';
import 'react-native-get-random-values'; // Required for uuidv4
import { v4 as uuid } from 'uuid';
import type { AppStore } from 'app/store';
import { setupStore } from 'app/store';
import { setAuth0Config } from 'app/features/auth/authSlice';
import { useAppStorage } from 'app/services/appStorage/useAppStorage';
import configureAxios from '../../services/axiosConfiguration/configureAxios';
import { sendToLogin } from './sendToLogin';

export const defaultStore = setupStore();

export interface StoreProviderProps extends Partial<ProviderProps> {
  children: ReactNode;

  // TODO: Clean up - add initialState instead of initialStore
  auth0Config?: {
    domain: string;
    clientId: string;
  };
}

export function StoreProvider({ store: initialStore, children, auth0Config }: StoreProviderProps) {
  const store = useMemo(() => (initialStore as AppStore | undefined) ?? defaultStore, [initialStore]);
  const [{ isLoading: isLoadingDeviceId, value: deviceId }, setDeviceId] = useAppStorage('deviceId');

  // Initializes store with any environment variables
  useEffect(() => {
    const initStore = () => {
      // Set Auth0 config
      if (auth0Config) {
        store.dispatch(setAuth0Config(auth0Config));
      }
    };

    initStore();
  }, [auth0Config, store]);

  // Generate a deviceId if one does not exist yet
  useEffect(() => {
    if (!isLoadingDeviceId && !deviceId) {
      void setDeviceId(uuid());
    }
  }, [deviceId, isLoadingDeviceId, setDeviceId]);

  useEffect(() => {
    const { requestInterceptorId, responseInterceptorId } = configureAxios(axiosInstance, store, sendToLogin);

    return () => {
      axiosInstance.interceptors.response.eject(requestInterceptorId);
      axiosInstance.interceptors.response.eject(responseInterceptorId);
    };
  }, [store]);

  return <Provider store={store}>{children}</Provider>;
}
