'use client';

import mitt from 'mitt';
import { createContext, useContext, useEffect, type ReactNode } from 'react';
import type { WebViewMessagingPayloads } from 'app/features/WebView/types';
import { webWebProcessorMountListeners, webWebProcessorUnMountListeners } from './webViewProcessor';

export interface EventBusProviderProps {
  children: ReactNode;
}

// Extend to other types as required to extend the bus to be used around the app
type BusMessages = WebViewMessagingPayloads;
const bus = mitt<BusMessages>();
const EventBusContext = createContext(bus);

export function EventBusProvider({ children }: EventBusProviderProps) {
  // Mount and remove listeners as required
  useEffect(() => {
    webWebProcessorMountListeners(bus);
    return () => {
      webWebProcessorUnMountListeners(bus);
    };
  }, []);
  return <EventBusContext.Provider value={bus}>{children}</EventBusContext.Provider>;
}

// Hook for using the bus in the app
export const useEventBus = () => useContext(EventBusContext);
