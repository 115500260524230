import type { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import type { AppStore } from '../../store';
import { refreshTokenWebNoInterceptor } from '../../api/no-interceptor/refreshTokenWebNoInterceptor';
import { axiosInstanceMutex, refreshTokenMutex } from '../mutex';
import { updateAuthInfo } from './updateAuthInfo';
import { setHeaders } from './setHeaders';
import { logoutAndRefresh } from './logoutAndRefresh';

const tokenLoginEndpoints = ['/login'];
const tokenLogoutEndpoints = ['/login/revokeSession'];
const tokenRefreshEndpoints = ['/login/refresh', '/v2/session/refresh'];
const tokenEndpoints = [...tokenLoginEndpoints, ...tokenRefreshEndpoints, ...tokenLogoutEndpoints];

const isTokenExpired = (expiry: number) => {
  return expiry <= Date.now();
};

// Function to set up our axios instance with interceptors
//  - Automatically refresh token if access token has expired
const configureAxios = <AxiosT extends AxiosInstance>(
  axiosInstance: AxiosT,
  store: AppStore,
  sendToLogin: () => void
) => {
  const requestInterceptorId = axiosInstance.interceptors.request.use(async (config) => {
    // Wait until any refresh token called from response interceptor is done.
    // Don't wait for refreshInstanceMutex to unlock as the external call acquires a lock, meaning it would deadlock here.
    await axiosInstanceMutex.waitForUnlock();

    await setHeaders({ headers: config.headers, store });

    return config;
  });

  // Updates token info as interceptor
  const responseInterceptorId = axiosInstance.interceptors.response.use(
    async (response: AxiosResponse) => {
      // If the request is a token request, i.e. login or refresh, update the token info
      if (tokenEndpoints.includes(response.config.url || '')) {
        await updateAuthInfo({ dispatch: store.dispatch });
      }

      // Return a successful response
      return response;
    },
    async (error: AxiosError) => {
      //This is called every time there is an axios error, so it tries to recover from it
      const originalConfig = error.config;

      // Type guard, should never happen
      if (!originalConfig) {
        return Promise.reject(error);
      }

      // If the request failed due to 401, it may be because of an expired token. If so, handle acquiring a new set of tokens
      if (
        error.response &&
        error.response.status === 401 &&
        !tokenEndpoints.includes(originalConfig.url || '') // Ignore if the request is related to login or refresh
      ) {
        // Check if tokens are expired
        const state = store.getState();
        const tokenInfo = state.auth.tokenInfo;

        // If no token expiry is set, the token is not there hence return.
        // Should never happen
        if (!tokenInfo.expiry) {
          await logoutAndRefresh({ store, sendToLogin });
          return Promise.reject(error);
        }

        const tokenIsExpired = isTokenExpired(tokenInfo.expiry);

        // If token is expired, refresh it
        if (tokenIsExpired) {
          // Wait until any external refresh token call is done, as that would invalidate the current set of tokens
          await refreshTokenMutex.waitForUnlock();
          if (!axiosInstanceMutex.isLocked()) {
            // Block other requests from running (request interceptor) while refresh token is occurring
            const release = await axiosInstanceMutex.acquire();
            try {
              const auth0Enabled = !!state.featureFlag.enableV2Auth0;
              await refreshTokenWebNoInterceptor({
                auth0Enabled,
                entityId: store.getState().auth.tokenInfo.entityAccountId || undefined, // Remain on the same entity account
              });
              await updateAuthInfo({ dispatch: store.dispatch });
              release();
              return axiosInstance(originalConfig); // Retry original request
            } catch (err) {
              // Refresh token failed, logout user and refresh the page
              release();
              await logoutAndRefresh({ store, sendToLogin });
              return Promise.reject(error);
            }
          } else {
            // A parallel request. Token might be refreshed now, retry the original request
            await axiosInstanceMutex.waitForUnlock();
            return axiosInstance(originalConfig);
          }
        }
      }

      // Refresh was called directly and failed. Just logout and redirect the user to the login page (with current route as redirect param)
      if (error.response && tokenRefreshEndpoints.includes(originalConfig.url || '')) {
        await logoutAndRefresh({ store, sendToLogin });
        return Promise.reject(error);
      }

      // Default response for errors unrelated to expired token
      return Promise.reject(error);
    }
  );
  return { requestInterceptorId, responseInterceptorId };
};

export default configureAxios;
